import React from 'react';

import Layout from '../components/layout';
import Contact from '../components/contact/contact';
import ContactMap from '../components/contact/contact-map';

export default function ContactPage() {
    return (
        <Layout title="Kontakt">
            <Contact />
            <ContactMap />
        </Layout>
    )
}